export function setupVideos(selector = '.video') {
    const videos = document.body.querySelectorAll(selector);

    for (let i = 0; i < videos.length; i++) {
        const videoPosition = videos[i].querySelector('.player-position');
        const videoId = videoPosition.getAttribute('data-video-id');

        let player;

        setTimeout(() => {
            player = new YT.Player(videoPosition.getAttribute('id'), {
                videoId,
                playerVars: {
                    'playsinline': 1
                }
            });
        }, 1000);

        videos[i].querySelector('.play-button').addEventListener('click', () => {
            videos[i].classList.add('active');
            player.playVideo();
        });

        videos[i].querySelector('.stop-button').addEventListener('click', () => {
            videos[i].classList.remove('active');
            player.pauseVideo();
        });
    }
}
